import React from 'react';

const Portfolio = () => {
  return (
    <div className="min-h-screen p-6 md:p-24 font-mono">
      {/* Navigation */}
      <nav className="flex justify-between items-center mb-16 text-sm">
        <a href="/" className="text-black hover:text-black">Haejin Jo</a>
        <div className="flex gap-6">
          <a href="#work" className="text-gray-600 hover:text-black">Work</a>
          <a href="#projects" className="text-gray-600 hover:text-black">Projects</a>
        </div>
      </nav>

      {/* Main Content */}
      <div className="max-w-2xl">
        <section className="mb-16">
          <p className="text-sm leading-relaxed mb-6">
            I build secure, scalable applications with a focus on cybersecurity. Currently working as a fullstack engineer using Laravel, Rails, and React Native.
          </p>
          <p className="text-sm leading-relaxed mb-6">
            My expertise spans both development and security, allowing me to create robust systems that protect user data while delivering excellent user experiences.
          </p>
          <p className="text-sm leading-relaxed">
            In my spare time, I explore emerging security trends and contribute to open-source security tools. I enjoy bridging the gap between security best practices and modern development workflows.
          </p>
        </section>

        {/* Work Experience */}
        <section className="mb-16">
          <h2 className="text-xs text-gray-400 mb-4 uppercase tracking-wide">Work</h2>
          <div className="space-y-8">
            <div>
              <div className="flex justify-between mb-1">
                <span className="text-sm">Full Stack Engineer</span>
                <span className="text-sm text-gray-400">2022—Present</span>
              </div>
              <p className="text-sm text-gray-600">
                Building secure applications with Laravel, Rails, and React Native. Implementing security best practices and modern authentication patterns.
              </p>
            </div>
          </div>
        </section>

        {/* Projects Section */}
        <section className="mb-16">
          <h2 className="text-xs text-gray-400 mb-4 uppercase tracking-wide">Projects</h2>
          <div className="space-y-8">
            <div>
              <h3 className="text-sm mb-1">Security Audit Dashboard</h3>
              <p className="text-sm text-gray-600">
                Internal tool for tracking and managing security vulnerabilities across multiple applications.
              </p>
            </div>
            <div>
              <h3 className="text-sm mb-1">Mobile Authentication System</h3>
              <p className="text-sm text-gray-600">
                Implemented biometric authentication in React Native with enhanced security protocols.
              </p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section>
          <h2 className="text-xs text-gray-400 mb-4 uppercase tracking-wide">Connect</h2>
          <div className="space-y-2">
            <p className="text-sm text-gray-600">
              <a href="https://linkedin.com" className="text-gray-600 hover:text-black">LinkedIn</a>
            </p>
            <p className="text-sm text-gray-600">
              <a href="https://github.com" className="text-gray-600 hover:text-black">GitHub</a>
            </p>
            <p className="text-sm text-gray-600">
              <a href="mailto:email@example.com" className="text-gray-600 hover:text-black">Email</a>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

function App() {
  return <Portfolio />;
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.tsx</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
